<template>
    <div class="Error404 full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height">
            <div class="content-area pa-8 flex-grow-1 bg-white d-flex flex-column justify-center align-center">
                <h1>{{ $t('404.sorry') }}</h1>
                <router-link to="/stations-overview" v-ripple class="content-btn">
                    {{ $t('404.button') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Error404',
    components: {
    },
    props: {
    },
    data () {
        return{
        }
    },
    computed: {
    },
    methods:{
    }
}
</script>
<style scoped lang="scss">
h1{
    padding-bottom: 1rem;
}
</style>